import { useState, useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import { ApolloClient, InMemoryCache, split, HttpLink } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { useAuth0 } from '@auth0/auth0-react'
import { onError } from 'apollo-link-error'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { addDependencyInitializer } from './applicationinsights'

// Supress normal graphql dependency calls
addDependencyInitializer((details: any) => {
  if (details.item.target === process.env.REACT_APP_X_HASURA_HTTPS_URI) {
    if (details.item?.properties?.requestHeaders?.graphqlname) {
      details.item.name = details.item.properties.requestHeaders.graphqlname
      delete details.item.properties.requestHeaders.graphqlname
    }
  }
})

export const GraphQLProvider = ({ children }: { children: any }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const [error, setError] = useState(false)

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_X_HASURA_HTTPS_URI || '',
    fetch,
  })

  const wsClient = new SubscriptionClient(
    process.env.REACT_APP_X_HASURA_WSS_URI || '',
    {
      lazy: true,
      reconnect: true,
      minTimeout: 4000, // default is 1000 which is short for a very poor data connection
      connectionParams: async () => {
        const token = isAuthenticated ? await getAccessTokenSilently() : null
        return {
          headers: {
            Authorization: token ? `Bearer ${token}` : undefined,
          },
        }
      },
    }
  )

  const wsLink = new WebSocketLink(wsClient)
  wsClient.onDisconnected((_) => setError(true))

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )

  const authLink = setContext(async (operation, { headers }) => {
    const token = isAuthenticated ? await getAccessTokenSilently() : null
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        graphqlname: `${
          (operation.query?.definitions?.[0] as any)?.operation || 'GraphQL'
        } ${operation.operationName}`,
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      console.error(`[Network error]: ${networkError}`)
      setError(true)
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${extensions.code}`
        )

        // Handle Hasura GraphQL errors-codes
        switch (extensions.code) {
          // invalid input syntax for type
          case 'data-exception':
            setError(false)
            break
          default:
            setError(true)
        }
      })
    }

    console.log(`[GQL Error]: ${graphQLErrors}`)
  })

  const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(splitLink) as any) as any,
    cache: new InMemoryCache(),
  })
  if (error) {
    return <ErrorOverlay error={error} setError={setError} />
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

const ErrorOverlay = styled(({ className, error, setError }) => {
  const [trigger, setTrigger] = useState(true)
  const { t } = useTranslation()

  error &&
    useEffect(() => {
      if (error) {
        const timer = setTimeout(
          () =>
            fetch(
              process.env.REACT_APP_X_HASURA_HTTPS_URI.replace(/\/v.*/, '') +
                '/healthz'
            )
              .then((r) => {
                if (r.status === 200) return false
                setTrigger(!trigger)
                return true
              })
              .then((d) => setError(d))
              .catch((err) => console.error(err, setTrigger(!trigger))),
          5000
        )
        return () => clearTimeout(timer)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger])

  return (
    <div className={className}>
      <h1>{t('restore_connections')}</h1>
    </div>
  )
})`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #00000050;
`
