import { useAuth0 } from '@auth0/auth0-react'
import { faSignOut } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import nemliatalvalogo from '../../images/nemliatalvalogo.png'
import { ActivationForm } from './ActivationForm'

interface NoCitizenProps {
  refetchCitizen: any
  refetchUser: any
}

export default function NoCitizen({
  refetchCitizen,
  refetchUser,
}: NoCitizenProps) {
  const { logout } = useAuth0()
  const { t } = useTranslation()

  return (
    <Wrapper>
      <ImageStyle src={nemliatalvalogo} alt="Nemlia Talva" />
      <ActivationContainer>
        <ActivationForm
          refetchCitizen={refetchCitizen}
          refetchUser={refetchUser}
        />
      </ActivationContainer>

      <LogoutLink onClick={() => logout({ returnTo: window.location.origin })}>
        <LinkText>{t(`settings.logout`)}</LinkText>
        <FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon>
      </LogoutLink>
    </Wrapper>
  )
}

const ActivationContainer = styled.div`
  margin-bottom: 50px;
`

const LinkText = styled.span`
  margin-right: 7px;
`
const LogoutLink = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const ImageStyle = styled.img`
  margin-bottom: 45px;
`
