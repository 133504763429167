import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { languages } from '@nemlia/talvatranslations'
import LanguageDetector from 'i18next-browser-languagedetector'

const detectionOptions = {
  order: [`querystring`, `localStorage`, `subdomain`, `navigator`, `htmlTag`],
  lookupFromPathIndex: 0,
  lookupQuerystring: 'lng',
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // lng: `fo`,
    resources: languages,
    fallbackLng: `en`,
    detection: detectionOptions,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
