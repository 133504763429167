import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Auth0Provider } from '@auth0/auth0-react'
import { GraphQLProvider } from './utils/Apollo'
import './i18n'
import ApplicationInsights from './utils/applicationinsights'

function app() {
  function RenderProvider() {
    return (
      <React.StrictMode>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
          clientId={process.env.REACT_APP_AUTH0_CLIENTID || ''}
          redirectUri={window.location.origin + '/callback'}
          audience="hasura"
        >
          <GraphQLProvider>
            <App />
          </GraphQLProvider>
        </Auth0Provider>
      </React.StrictMode>
    )
  }

  function RenderDom() {
    return <ApplicationInsights>{RenderProvider()}</ApplicationInsights>
  }

  ReactDOM.render(RenderDom(), document.getElementById('root'))
}

app()

serviceWorker.unregister()
