import i18n from '../i18n'

export const getListOfLanguages = () => {
  let languages = [
    { value: `dk`, label: 'Dansk' },
    { value: `en`, label: 'English' },
    { value: `fo`, label: 'Føroyskt' },
    { value: `de`, label: 'Deutsch' },
    { value: `fr`, label: 'Française' },
  ]

  return languages
}

export function apiUrl() {
  const env = process.env.REACT_APP_TALVA_ENVIRONMENT
  let url

  if (env === 'development') {
    url = 'https://api.dev.techcare.fo'
  } else if (env === 'staging') {
    url = 'https://pipit.staging.nemlia.com'
  } else {
    url = 'https://pipit.prod.nemlia.com'
  }

  return url
}

export async function callApi({
  url,
  data,
  onSuccess,
  onError,
  method = 'POST',
  jwt,
}: any) {
  let myHeaders = new Headers()
  myHeaders.append('accept', 'application/json')
  myHeaders.append('Authorization', `Bearer ${jwt}`)
  myHeaders.append('Content-Type', 'application/json')

  const requestOptions = {
    method,
    headers: myHeaders,
    body: JSON.stringify(data),
  }

  await fetch(`${apiUrl()}/rest/v1${url}`, requestOptions)
    .then(onSuccess)
    .catch(onError)
}

export function getNumberFormatLocale(lng: string) {
  switch (lng) {
    case 'en':
      return 'en-EN'
    case 'fo':
      return 'da-DK'
    case 'da':
    case 'dk':
      return 'da-DK'
  }

  return
}

interface FormatNumberOptions {
  decimals?: number
}

export function formatNumberFromString(
  value: string,
  options?: FormatNumberOptions
) {
  const number = parseFloat(value)

  return formatNumber(number, options)
}

export function formatNumber(value: number, options?: FormatNumberOptions) {
  const locale = getNumberFormatLocale(i18n.language)
  const decimals = options?.decimals

  return value.toLocaleString(locale, {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  })
}
